import $ from "jquery";
import 'bootstrap';
import './assets/styles/main.scss';

import './assets/scripts/FileUpload';

$(document).ready(() => {
	const menuButton = $('.header-menu-button');
	const navButton = $('.header-navigation-button');

	menuButton.on('click', () => {
		$('body').toggleClass('menu-is-open');
	});

	navButton.on('click', () => {
		$('body').removeClass('menu-is-open');
	});
});
